<script>
export default {
  name: 'Segmented',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    itemWidth: {
      type: Number,
      default: 80
    },
    backgroundColor: {
      type: String,
      default: '#ddd'
    },
    selectedColor: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      selected: 0,
      style: {
        zIndex: 9,
        width: this.itemWidth + 'px',
        height: '32px',
        left: '0px',
        position: 'absolute',
        background: this.selectedColor
      }
    }
  },
  watch: {
    itemWidth: function (oldVal, newVal) {
      this.style.width = newVal + 'px'
    }
  },
  methods: {
    handleClick (index) {
       this.animate(this.itemWidth * index)
       this.$emit('input', this.options[index].value)
       this.$emit('change', this.options[index].value)
    },
    animate (left) {
      const _this = this
      const step = (left - parseInt(_this.style.left.replace('px', ''))) / 5
      let index = 0
      let timer = setInterval(function () {
        if (index >= 5) {
          clearInterval(timer)
          timer = null
        } else {
          _this.style.left = (parseInt(_this.style.left.replace('px', '')) + step) + 'px'
        }
        index++
      }, 50)
    }
  }
}
</script>

<template>
  <div class="container" :style="{backgroundColor:backgroundColor,width:itemWidth*options.length+'px'}">
    <div :style="style" class="identification"></div>
    <div class="segments-group">
      <div v-for="(item, index) in options" :key="index" class="segment-item" :style="{width:itemWidth+'px'}" @click="handleClick(index)">{{ item.label }}</div>
    </div>

  </div>
</template>

<style scoped lang='less'>
   .container{
     display: inline-block;
     position: relative;
     height: 32px;
     line-height: 32px;
     border-radius: 6px;
     outline: 1px solid #ccc;

     .segments-group{
       width: 100%;
       left:0;
       top:0;
       bottom:0;
       right:0;
       position: absolute;
       z-index: 11;
     }

     .segment-item{
       display: inline-block;
       text-align: center;
       cursor: pointer;
     }

     .identification{
       border-radius: 6px;
     }
   }
</style>
