<script xmlns=''>
import Segmented from '@/components/Segmented/Segmented.vue'
import { getUserAttendanceRecord, loadPermissionList, loadUserAttendanceRecord } from '@/api/data'
import moment from 'moment'
import STable from '@/components/Table'
import { getI18nLanguage } from '@/locales'

export default {
  name: 'AttendanceStat',
  components: { Segmented, STable },
  data () {
    return {
      mode: 1,
      columns: [{
        title: this.$t('user.name'),
        align: 'center',
        width: 160,
        dataIndex: 'name',
        fixed: 'left'
      }, {
        title: this.$t('user.checkin.work.dur'),
        align: 'center',
        width: 140,
        dataIndex: 'work_dur'
      }, {
        title: this.$t('user.checkin.rest.dur'),
        align: 'center',
        width: 140,
        dataIndex: 'rest_dur'
      }, {
        title: this.$t('user.checkin.work.dur.real'),
        align: 'center',
        width: 140,
        dataIndex: 'real_work_dur'
      },
        {
          title: this.$t('user.checkin.action.clockin'),
          align: 'center',
          children: [
            // {
            //   title: '规定时间',
            //   width: 100,
            //   dataIndex: 'on_rule_time',
            //   align: 'center'
            // },
            {
              title: this.$t('user.checkin.time'),
              width: 100,
              dataIndex: 'on_real_time',
              align: 'center'
            }
          ]
        },
        {
          title: this.$t('user.checkin.action.clockout'),
          align: 'center',
          children: [
            // {
            //   title: '规定时间',
            //   width: 100,
            //   dataIndex: 'out_rule_time',
            //   align: 'center'
            // },
            {
              title: this.$t('user.checkin.time'),
              width: 100,
              dataIndex: 'out_real_time',
              align: 'center'
            }
          ]
        },
        {
          title: this.$t('user.checkin.dur'),
          children: [
            {
              title: '4',
              align: 'center',
              customRender: (value, row, index) => {
                const bars = value.bars
                let mIndex = 0
                const listItems = bars.map((bar) => {
                  const divStyle = {
                    position: 'absolute',
                    overflow: 'hidden',
                    top: mIndex === 0 ? '1px' : 0,
                    'z-index': 100 + mIndex,
                    left: bar.left,
                    right: bar.right,
                    height: '32px',
                    'background-color': bar.color
                  }
                  mIndex++
                  const title = bar.title
                  return <div style={divStyle} title={title}></div>
                })
                const obj = {
                  style: { padding: 0, margin: 0 },
                  children:
                    <div style='position:relative;width:100%;height:32px;overflow:hidden;padding:0;'>
                      {listItems}
                    </div>,
                  attrs: {
                    colSpan: 24
                  }
                }
                return obj
              }
            },
            {
              title: '5',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '6',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '7',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '8',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '9',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '10',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '11',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '12',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '13',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '14',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '15',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '16',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '17',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '18',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '19',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '20',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '21',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '22',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '23',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '0',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '1',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '2',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            },
            {
              title: '3',
              align: 'center',
              customRender: (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {
                    colSpan: 0
                  }
                }
                return obj
              }
            }
          ]
        }
        // {
        //   title: '备注',
        //   align: 'center',
        //   width: 160,
        //   dataIndex: 'remark',
        //   fixed: 'right'
        // }, {
        //   title: '操作',
        //   align: 'center',
        //   width: 160,
        //   dataIndex: 'action',
        //   fixed: 'right'
        // }
      ],
      queryParams: { type: 1, date: moment().format('YYYY-MM-DD') },
      data: [],
      driverInfoMap: {},
      loading: false,
      options: [{
        label: this.$t('user.checkin.stat.single.day'),
        value: 1
      }, { label: this.$t('user.checkin.stat.more.day'), value: 2 }],
      columns3: [{
        title: this.$t('user.name'),
        align: 'center',
        dataIndex: 'name'
      }, {
        title: this.$t('user.account'),
        align: 'center',
        dataIndex: 'account'
      }, {
        title: this.$t('user.checkin.action'),
        scopedSlots: { customRender: 'action' },
        align: 'center',
        dataIndex: 'type'
      }, {
        title: this.$t('user.checkin.date'),
        align: 'center',
        dataIndex: 'sign_date'
      }, {
        title: this.$t('user.checkin.time'),
        align: 'center',
        scopedSlots: { customRender: 'sign_time' },
        dataIndex: 'sign_time'
      }, {
        title: this.$t('user.checkin.location'),
        align: 'center',
        scopedSlots: { customRender: 'location' },
        dataIndex: 'sign_time'
      }],
      queryParams3: {
        uid: null,
        date: null,
        type: null
      },
      queryParams2: {
        month: moment()
      },
      height: document.body.clientHeight - 290,
      width: document.body.clientWidth,
      alertMessage: null,
      cDriver: null,
      loadData3: (paramter) => {
        const q = Object.assign({}, this.queryParams3)
        if (q.date) {
          q.date = q.date.format('YYYY-MM-DD')
        }
        return getUserAttendanceRecord(Object.assign(paramter, q)).then(res => {
          var data = res.result.data
          for (const item of data) {
            item.name = this.driverInfoMap[item.uid].name
            item.account = this.driverInfoMap[item.uid].account
          }
          return res.result
        })
      },
      data3: [],
      proDrivers: [],
      drivers: []
    }
  },
  created () {
    loadPermissionList({ pageNo: 1, pageSize: 100 }).then(res => {
      const ds = res.result.data
      this.drivers = ds
      const users = []
      const proUsers = []
      let expireNum = 0
      const proDrivers = []
      const driverInfoMap = {}
      for (const item of ds) {
        if (item.user_level > 0 && moment(item.level_expire).isAfter(moment())) {
          proUsers.push(item.user_id)
          driverInfoMap[item.user_id] = {
            name: item.name,
            account: item.account
          }
          proDrivers.push(item)
        } else {
          expireNum++
        }
        users.push(item.user_id)
      }
      this.proDrivers = proDrivers
      if (proUsers.length > 0) {
        this.queryParams2.uid = proUsers[0]
      }
      if (expireNum > 0) {
        this.alertMessage = this.$t('user.checkin.stat.expire.tip').replace('{num}', expireNum)
      }

      this.driverInfoMap = driverInfoMap
      this.queryParams.uid = proUsers
      this.queryParams3.users = users
      this.loadData()
    })
  },
  methods: {
    getI18nLanguage,
    moment,
    loadData () {
      this.data = []
      this.loading = true
      if (this.mode === 1) {
        loadUserAttendanceRecord(this.queryParams).then(res => {
          const records = res.data
          const dRecord = {}
          for (const item of records) {
            if (!dRecord[item.uid]) {
              dRecord[item.uid] = {
                breakStart: false,
                breakTmp: [],
                breaks: []
              }
            }
            switch (item.type) {
              case 1:
                if (!dRecord[item.uid].on_real_time) {
                  dRecord[item.uid].on_real_time = moment(item.sign_time).format('HH:mm')
                  dRecord[item.uid].work_start_time = item.sign_time
                }
                break
              case 2:
                dRecord[item.uid].out_real_time = moment(item.sign_time).format('HH:mm')
                dRecord[item.uid].work_end_time = item.sign_time
                if (dRecord[item.uid].breakStart) {
                  // 开始休息但未结束休息直接下班
                  dRecord[item.uid].breakTmp.push(item.sign_time)
                  dRecord[item.uid].breaks.push(dRecord[item.uid].breakTmp)
                }
                break
              case 3:
                if (!dRecord[item.uid].breakStart) {
                  dRecord[item.uid].breakStart = true
                  dRecord[item.uid].breakTmp.push(item.sign_time)
                }

                break
              case 4:
                if (!dRecord[item.uid].breakStart) {
                  // extend into the next day
                  dRecord[item.uid].breakTmp.push(moment(this.queryParams.date + ' 00:00:00').valueOf())
                  dRecord[item.uid].breakTmp.push(item.sign_time)
                  dRecord[item.uid].breaks.push(dRecord[item.uid].breakTmp)
                } else {
                  dRecord[item.uid].breakTmp.push(item.sign_time)
                  dRecord[item.uid].breaks.push(dRecord[item.uid].breakTmp)
                }
                dRecord[item.uid].breakTmp = []
                dRecord[item.uid].breakStart = false
                break
            }
          }
          const ds = Object.keys(this.driverInfoMap)
          const data = []
          // eslint-disable-next-line camelcase
          let work_dur = 0
          // eslint-disable-next-line camelcase
          let restDur = 0
          for (const d of ds) {
            const bars = []
            const name = this.driverInfoMap[d].name
            if (dRecord[d]) {
              if ((!dRecord[d].work_start_time) && dRecord[d].work_end_time) {
                dRecord[d].work_start_time = moment(this.queryParams.date + ' 00:00:00').valueOf()
              }
              if ((dRecord[d].work_start_time) && (!dRecord[d].work_end_time)) {
                if (this.queryParams.date === moment().format('YYYY-MM-DD')) {
                  dRecord[d].work_end_time = moment().valueOf()
                } else {
                  dRecord[d].work_end_time = moment(this.queryParams.date + ' 23:59:59').valueOf()
                }
              }
              // eslint-disable-next-line camelcase
              work_dur = ((dRecord[d].work_end_time - dRecord[d].work_start_time) / 3600000).toFixed(2)
              const dayStart = moment(this.queryParams.date + ' 04:00:00').valueOf()
              const dayEnd = moment(this.queryParams.date + ' 00:00:00').add(1, 'days').valueOf()
              if (dRecord[d].work_start_time < dayStart) {
                if (dRecord[d].work_end_time > dayStart) {
                  // 4->end
                  bars.push({
                    left: '0%',
                    right: (4 * 3600000 - dRecord[d].work_end_time + dayEnd) * 100 / (24 * 3600000) + '%',
                    color: '#00CC33',
                    title: '04:00~' + moment(dRecord[d].work_end_time).format('HH:mm') + ' ' + this.$t('user.attendance.work')
                  })
                }
                // start->(end<4?end:4)
                bars.push({
                  left: ((dRecord[d].work_start_time + 24 * 3600000 - dayStart) * 100 / (24 * 3600000) + '%'),
                  right: dRecord[d].work_end_time >= dayStart ? 0 : ((dayStart - dRecord[d].work_end_time) * 100 / (24 * 3600000) + '%'),
                  color: '#00CC33',
                  title: moment(dRecord[d].work_start_time).format('HH:mm') + '~' + (dRecord[d].work_end_time >= dayStart ? '04:00' : moment(dRecord[d].work_end_time)) + ' ' + this.$t('user.attendance.work')
                })
              } else {
                bars.push({
                  left: (dRecord[d].work_start_time - dayStart) * 100 / (24 * 3600000) + '%',
                  right: (4 * 3600000 - dRecord[d].work_end_time + dayEnd) * 100 / (24 * 3600000) + '%',
                  color: '#00CC33',
                  title: moment(dRecord[d].work_start_time).format('HH:mm') + '~' + moment(dRecord[d].work_end_time).format('HH:mm') + ' ' + this.$t('user.attendance.work')
                })
              }
              const breaks = dRecord[d].breaks
              if (breaks && breaks.length > 0) {
                for (const b of breaks) {
                  // eslint-disable-next-line camelcase
                  const df = b[1] - b[0]
                  restDur = restDur + df
                  bars.push({
                    left: (b[0] - dayStart) * 100 / (24 * 3600000) + '%',
                    right: (4 * 3600000 - b[1] + dayEnd) * 100 / (24 * 3600000) + '%',
                    color: '#FFCC00',
                    title: moment(b[0]).format('HH:mm') + '~' + moment(b[1]).format('HH:mm') + ' ' + this.$t('user.attendance.rest')
                  })
                }
              }
            }
            // eslint-disable-next-line camelcase
            restDur = restDur / 3600000
            data.push({
              name: name,
              on_real_time: dRecord[d] ? dRecord[d].on_real_time : null,
              out_real_time: dRecord[d] ? dRecord[d].out_real_time : null,
              bars: bars,
              work_dur: work_dur,
              rest_dur: restDur.toFixed(2),
              // eslint-disable-next-line camelcase
              real_work_dur: (work_dur - restDur).toFixed(2)
            })
          }
          this.data = data
          this.loading = false
        })
      } else if (this.mode === 2) {
        loadUserAttendanceRecord({
          month: this.queryParams2.month.format('YYYY-MM'),
          uid: this.queryParams2.uid
        }).then(res => {
          const records = res.data
          const dMap = {}
          for (const item of records) {
            const date = moment(item.sign_time).format('YYYY-MM-DD')
            if (!dMap[date]) {
              dMap[date] = []
            }
            dMap[date].push(item)
          }
          const data = []
          const mMonth = this.queryParams2.month.format('YYYY-MM')
          const startMoment = this.queryParams2.month.format(mMonth + '-01 00:00:00')

          for (let i = 1; i <= 31; i++) {
            const date = moment(startMoment).add(i - 1, 'days').format('YYYY-MM-DD')

            if (date.startsWith(mMonth)) {
              if (dMap[date]) {
                const dayStat = this.calcDayAttendanceStat(dMap[date], date)
                data.push(Object.assign({
                  stat_date: date
                }, dayStat))
              } else {
                data.push({
                  stat_date: date,
                  bars: []
                })
              }
            }
          }
          this.data = data
          this.loading = false
        })
      }
    },
    calcDayAttendanceStat (records, date) {
      const dRecord = {
        breakStart: false,
        breakTmp: [],
        breaks: []
      }
      for (const item of records) {
        switch (item.type) {
          case 1:
            if (!dRecord.on_real_time) {
              dRecord.on_real_time = moment(item.sign_time).format('HH:mm')
              dRecord.work_start_time = item.sign_time
            }
            break
          case 2:
            dRecord.out_real_time = moment(item.sign_time).format('HH:mm')
            dRecord.work_end_time = item.sign_time
            if (dRecord.breakStart) {
              // 开始休息但未结束休息直接下班
              dRecord.breakTmp.push(item.sign_time)
              dRecord.breaks.push(dRecord.breakTmp)
            }
            break
          case 3:
            if (!dRecord.breakStart) {
              dRecord.breakStart = true
              dRecord.breakTmp.push(item.sign_time)
            }

            break
          case 4:
            if (!dRecord.breakStart) {
              // extend into the next day
              dRecord.breakTmp.push(moment(date + ' 00:00:00').valueOf())
              dRecord.breakTmp.push(item.sign_time)
              dRecord.breaks.push(dRecord.breakTmp)
            } else {
              dRecord.breakTmp.push(item.sign_time)
              dRecord.breaks.push(dRecord.breakTmp)
            }
            dRecord.breakTmp = []
            dRecord.breakStart = false
            break
        }
      }

      // eslint-disable-next-line camelcase
      let work_dur = 0
      // eslint-disable-next-line camelcase
      let restDur = 0

      const bars = []

      if ((!dRecord.work_start_time) && dRecord.work_end_time) {
        dRecord.work_start_time = moment(date + ' 00:00:00').valueOf()
      }
      if ((dRecord.work_start_time) && (!dRecord.work_end_time)) {
        if (date === moment().format('YYYY-MM-DD')) {
          dRecord.work_end_time = moment().valueOf()
        } else {
          dRecord.work_end_time = moment(date + ' 23:59:59').valueOf()
        }
      }
      // eslint-disable-next-line camelcase
      work_dur = ((dRecord.work_end_time - dRecord.work_start_time) / 3600000).toFixed(2)
      const dayStart = moment(date + ' 04:00:00').valueOf()
      const dayEnd = moment(date + ' 00:00:00').add(1, 'days').valueOf()
      if (dRecord.work_start_time < dayStart) {
        if (dRecord.work_end_time > dayStart) {
          // 4->end
          bars.push({
            left: '0%',
            right: (4 * 3600000 - dRecord.work_end_time + dayEnd) * 100 / (24 * 3600000) + '%',
            color: '#00CC33',
            title: '04:00~' + moment(dRecord.work_end_time).format('HH:mm') + ' ' + this.$t('user.attendance.work')
          })
        }
        // start->(end<4?end:4)
        bars.push({
          left: ((dRecord.work_start_time + 24 * 3600000 - dayStart) * 100 / (24 * 3600000) + '%'),
          right: dRecord.work_end_time >= dayStart ? 0 : ((dayStart - dRecord.work_end_time) * 100 / (24 * 3600000) + '%'),
          color: '#00CC33',
          title: moment(dRecord.work_start_time).format('HH:mm') + '~' + (dRecord.work_end_time >= dayStart ? '04:00' : moment(dRecord.work_end_time)) + ' ' + this.$t('user.attendance.work')
        })
      } else {
        bars.push({
          left: (dRecord.work_start_time - dayStart) * 100 / (24 * 3600000) + '%',
          right: (4 * 3600000 - dRecord.work_end_time + dayEnd) * 100 / (24 * 3600000) + '%',
          color: '#00CC33',
          title: moment(dRecord.work_start_time).format('HH:mm') + '~' + moment(dRecord.work_end_time).format('HH:mm') + ' ' + this.$t('user.attendance.work')
        })
      }
      const breaks = dRecord.breaks
      if (breaks && breaks.length > 0) {
        for (const b of breaks) {
          // eslint-disable-next-line camelcase
          const df = b[1] - b[0]
          restDur = restDur + df
          bars.push({
            left: (b[0] - dayStart) * 100 / (24 * 3600000) + '%',
            right: (4 * 3600000 - b[1] + dayEnd) * 100 / (24 * 3600000) + '%',
            color: '#FFCC00',
            title: moment(b[0]).format('HH:mm') + '~' + moment(b[1]).format('HH:mm') + ' ' + this.$t('user.attendance.rest')
          })
        }
      }

      // eslint-disable-next-line camelcase
      restDur = restDur / 3600000
      return {
        on_real_time: dRecord ? dRecord.on_real_time : null,
        out_real_time: dRecord ? dRecord.out_real_time : null,
        bars: bars,
        work_dur: work_dur,
        rest_dur: restDur.toFixed(2),
        // eslint-disable-next-line camelcase
        real_work_dur: (work_dur - restDur).toFixed(2)
      }
    },
    handleModeChange (e) {
      this.mode = e
      switch (e) {
        case 1:
          this.columns.splice(0, 1, {
            title: this.$t('user.name'),
            align: 'center',
            width: 160,
            dataIndex: 'name',
            fixed: 'left'
          })

          break
        case 2:
          this.columns.splice(0, 1, {
            title: this.$t('user.checkin.stat.date'),
            align: 'center',
            width: 160,
            dataIndex: 'stat_date',
            fixed: 'left'
          })
          break
      }
      this.loadData()
    },
    handleChangeDate (e) {
      this.queryParams.date = e.format('YYYY-MM-DD')
      this.loadData()
    },
    handleChangeMonth (e) {
      this.queryParams2.month = e
      this.loadData()
    },
    handleChangeDriver (e) {
      this.queryParams2.uid = e
      this.loadData()
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    refreshTable3 () {
      this.$refs.table3.refresh()
    }
  }
}
</script>

<template>
  <div style="background: white;">
    <a-tabs>
      <a-tab-pane :key="1" :tab="$t('user.checkin.stat')">
        <div style="overflow: hidden;padding:0 8px;">
          <div style="margin-bottom: 8px;">
            <a-alert
              v-if="alertMessage"
              :message="alertMessage"
              banner
              closable></a-alert>
          </div>
          <div class="float-left" style="display: flex;">
            <div>
              <segmented :itemWidth="140" :options="options" @change="handleModeChange"></segmented>
            </div>
            <div v-if="mode===1" class="margin-left-16">
              <span>{{ $t('user.checkin.stat.date') }}:</span>
              <a-date-picker v-model="queryParams.date" style="margin-left: 8px;" @change="handleChangeDate" />
            </div>
            <div v-if="mode===2" class="margin-left-16">
              <span>{{ $t('user.checkin.stat.month') }}:</span>
              <a-month-picker
                v-model="queryParams2.month"
                style="width: 120px;margin-left: 8px;"
                @change="handleChangeMonth" />
            </div>
            <div v-if="mode===2" class="margin-left-16">
              <span>{{ $t('user.checkin.driver') }}:</span>
              <a-select v-model="queryParams2.uid" style="width: 160px;margin-left: 8px;" @change="handleChangeDriver">
                <a-select-option v-for="driver in proDrivers" :key="driver.user_id" :value="driver.user_id">
                  {{ driver.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="float-right" style="display: flex;height: 32px;line-height: 20px;padding-top: 6px;" v-if="width>=1000">
            <div style="background:#00CC33;width: 20px;height: 20px; "></div>
            <div style="margin-left: 8px;height: 20px;">{{ $t('user.attendance.work.dur') }}</div>
            <div style="background:#FFCC00;width: 20px;margin-left: 20px;height: 20px; "></div>
            <div style="margin-left: 8px;height: 20px;">{{ $t('user.attendance.rest.dur') }}</div>

          </div>
        </div>
        <div class="ant-card">
          <a-table
            :columns="columns"
            :data-source="data"
            :loading="loading"
            :pagination="false"
            :scroll="{x:1600,y:height}"
            bordered
            size="small">
            <div slot="detail" style="background: #0b6bb7;"></div>
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="2" :tab="$t('user.checkin.record')">
        <div style="padding:0 8px;">
          <a-row :gutter="12">
            <a-col :span="5">
              <div class="form-item">
                <span class="form-item-label">{{ $t('user.name') }}</span>
                <a-select
                  v-model="queryParams3.uid"
                  :filter-option="filterOption"
                  allowClear
                  option-filter-prop="children"
                  show-search
                  style="flex:1;">
                  <a-select-option v-for="driver in drivers" :key="driver.user_id" :value="driver.user_id">
                    {{ driver.name }}
                  </a-select-option>
                </a-select>
              </div>

            </a-col>
            <a-col :span="5">
              <div class="form-item">
                <span class="form-item-label">{{ $t('user.checkin.date') }}</span>
                <a-date-picker v-model="queryParams3.date" style="flex:1;"></a-date-picker>
              </div>

            </a-col>
            <a-col :span="5">
              <div class="form-item">
                <span class="form-item-label">{{ $t('user.checkin.action') }}</span>
                <a-select v-model="queryParams3.type" allowClear style="flex:1;">
                  <a-select-option :value="1">{{ $t('user.checkin.action.clockin') }}</a-select-option>
                  <a-select-option :value="2">{{ $t('user.checkin.action.clockout') }}</a-select-option>
                  <a-select-option :value="3">{{ $t('user.checkin.action.breakstart') }}</a-select-option>
                  <a-select-option :value="4">{{ $t('user.checkin.action.breakend') }}</a-select-option>
                </a-select>
              </div>

            </a-col>
            <a-col :span="6">
              <div class="form-item">
                <a-button @click="refreshTable3">{{ $t('user.action.search') }}</a-button>
              </div>
            </a-col>
          </a-row>
        </div>
        <div style="margin-top: 8px;">
          <s-table
            ref="table3"
            :columns="columns3"
            :data="loadData3"
            :loading="loading"
            bordered
            size="small">
            <span slot="sign_time" slot-scope="text, record"> {{ moment(record.sign_time).format('HH:mm:ss') }}</span>
            <span slot="location" slot-scope="text, record"><a
              :href="'https://www.google.com/maps/place/'+record.loc_gl"
              target="_blank">{{ record.loc_str }}</a></span>
            <div slot="action" slot-scope="text, record">
              <span v-if="record.type==1">{{ $t('user.checkin.action.clockin') }}</span>
              <span v-if="record.type==2">{{ $t('user.checkin.action.clockout') }}</span>
              <span v-if="record.type==3">{{ $t('user.checkin.action.breakstart') }}</span>
              <span v-if="record.type==4">{{ $t('user.checkin.action.breakend') }}</span>
            </div>
          </s-table>
        </div>
      </a-tab-pane>
    </a-tabs>

  </div>
</template>

<style lang='less' scoped>
.form-item {
  display: flex;

  .form-item-label {
    line-height: 32px;
    margin-right: 8px;
  }
}
</style>
<style>
.ant-tabs-bar{
  margin: 0 0 8px 0;
}
</style>
